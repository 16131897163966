import SideMenu from "../../../components/sidemenu";
import {Button, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from "../../../utils/endpoints.json";
import {PencilSquare} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

export default function EditableImages() {

    const [images, setImages] = useState(null);

    useEffect(() => {
        axios.get(endpoints.APICalls + `/admin/editableImages`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                console.log(resp.data)
                setImages(resp.data)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Редактируемые изображения</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 flex-w'}>
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>Mетка</td>
                            <td>Превью</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            images && (
                                images.length > 0 && (
                                    images.map((item) =>
                                        <tr>
                                            <td>{item.label}</td>
                                            <td>
                                                <img src={endpoints.MediaURL + item.url} alt={item.label} style={{ width: "128px" }} />
                                            </td>
                                            <td>
                                                <Link to={`/dashboard/eis/edit/${item.id}`}><Button
                                                variant={'secondary'}><PencilSquare/></Button></Link>
                                            </td>
                                        </tr>
                                    )
                                )
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>

        </div>
    )
}