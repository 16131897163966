import SideMenu from "../../../components/sidemenu";
import {Link} from "react-router-dom";
import {Button, Form, Table} from "react-bootstrap";
import {Link45deg, PencilSquare, PlusSquareFill, Trash} from "react-bootstrap-icons";
import endpoints from "../../../utils/endpoints.json";
import {useEffect, useState} from "react";
import axios from "axios";

export default function UsersList() {

    const [users, setUsers] = useState(null);

    useEffect(() => {
        axios.get(endpoints.APICalls + `/admin/users/all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then((resp) => {
                console.log(resp.data)
                setUsers(resp.data)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])



    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Пользователи</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>
                <div className={'flex-grow-1 flex-w'}>
                    <Table>
                        <thead>
                        <tr className={'fw-bold'}>
                            <td>ID</td>
                            <td>Имя</td>
                            <td>Email</td>
                            <td>Телефон</td>
                            <td>Дата регистрации</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            users && (
                                users.length > 0 && (
                                    users.map((item) =>
                                        <tr>
                                            <td>{item.id}</td>
                                            <td>{item.firstname}</td>
                                            <td>{item.email}</td>
                                            <td>{item.mobilePhone}</td>
                                            <td>{new Date(item.joined_at).toLocaleDateString('ru-ru')}</td>
                                        </tr>
                                    )
                                )
                            )


                        }
                        </tbody>
                    </Table>
                </div>
            </div>

        </div>
    )
}