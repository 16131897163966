import SideMenu from "../../../components/sidemenu";
import {Alert, Button, Form} from "react-bootstrap";
import endpoints from "../../../utils/endpoints.json";
import ProductForm from "../../../components/product_form";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";

export default function EditEditableImage(props) {

    const {eid} = useParams();

    const [done, setDone] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [image, setImage] = useState(null);

    const [newImage, setNewImage] = useState(null);

    useEffect(() => {
        axios.get(endpoints.APICalls + `/utils/editableImage?eid=${eid}`)
            .then((resp) => {
                setImage(resp.data)
            })
    }, [eid])

    const updateImage = () => {
        axios.post(endpoints.APICalls + `/admin/editableImages/`, {
            eid: eid,
            newImage: newImage,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((resp) => {
                window.location.reload();
            })
    }

    return (
        <div className={'container vw-100 pt-5'}>
            <h1>Редактирование изображения</h1>
            <div className={'d-flex flex-column flex-lg-row'}>
                <SideMenu/>

                <div className={'flex-grow-1 flex-w'}>
                    {
                        done ? (
                            updated ? (
                                <Alert variant={'success'}>
                                    <p><b>Изображение обновлено</b></p>
                                </Alert>
                            ) : (
                                <Alert variant={'danger'}>
                                    <p><b>Ошибка</b></p>
                                    <p>Возникла ошибка при обновлении. Пожалуйста, обратитесь в службу
                                        поддержки.</p>
                                </Alert>
                            )
                        ) : (

                            image ? (
                                <>
                                    <div className={'d-flex justify-content-between'}>
                                        <Form.Control id={'photoloader'} type="file" placeholder="Обложка"
                                                      onChange={(e) => {
                                                          setNewImage(e.target.files[0])
                                                      }}/>
                                        <img src={endpoints.MediaURL + image.url} className={''}
                                             style={{width: '128px'}}/>
                                    </div>
                                    <Button onClick={updateImage}>Обновить изображение</Button>
                                </>

                            ) : (
                                <p>Получаем данные о товаре</p>
                            )


                        )
                    }

                </div>
            </div>

        </div>
    )
}